import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class RolesServices extends baseRestService {

  constructor() {
    super();
    this.baseUrl = () => `${settings.authService.endpoint}/identity/roles`;
  }

  /**
   * Get all the available roles from the backend
   * @returns an Array of Role
   */
  public async getRoles(): Promise<Array<role.item>> {
    return await this.Get<Array<role.item>>("");
  }

  /**
   * Get all the claims related to the specified role name
   * @param roleName the name of the role
   * @returns an Array of Claims related to that roleName
   */
  public async getRoleClaims(roleName: string): Promise<Array<role.claims>> {
    return await this.Get<Array<role.claims>>(`/${roleName}/claims`);
  }

  /**
   * Fetch all the available claims
   * @returns the Array of all available claims
   */
  public async getAllClaims(): Promise<Array<role.claim>> {
    return await this.Get<Array<role.claim>>("/claims");
  }

  /**
   * Creates relationship between Role and Claim
   * @param roleName the role name who will have new claim
   * @param claimType the claim to associate with the role
   * @returns a RoleClaim object representing the new association
   */
  public async createNewRoleClaim(roleName: string, claimType: string): Promise<Array<role.claims>> {
    return await this.Post<Array<role.claims>>(`/${roleName}/claim/${claimType}`, {});
  }

  /**
   * Deletes relationship between Role and Claim
   * @param roleName the role name who will have new claim
   * @param claimType the claim to associate with the role
   * @returns a RoleClaim object representing the deleted association
   */
  public async deleteRoleClaim(roleName: string, claimType: string): Promise<role.claims> {
    return await this.Delete<role.claims>(`/${roleName}/claim/${claimType}`);
  }

  /**
   * Creates a new Role with the specified name
   * @param roleName the name of the Role to be created
   */
  public async createNewRole(roleName: string): Promise<void> {
    await this.Post(`/${roleName}`, {});
  }

  /**
   * Deletes a Role
   * @param roleName name of the role to delete
   */
  public async deleteRole(roleName: string): Promise<void> {
    await this.Delete(`/${roleName}`);
  }

  /**
   * Get all users related to a specific role
   * @param roleName the roleName users are linked to
   * @returns an Array of UserSummary
   */
  public async getRoleUsers(roleName: string): Promise<Array<user.summary>> {
    return await this.Get(`/${roleName}/users`);
  }

  /**
   * Relates a User to a Role
   * @param roleName the Role Name that will be related to a user
   * @param userId the UserId that will receive Role
   */
  public async assignRoleToUser(roleName: string, userId: string): Promise<void> {
    await this.Post(`/${roleName}/user/${userId}`, {});
  }

  public async removeUserFromRole(roleName: string, userId: string): Promise<void> {
    await this.Delete(`/${roleName}/user/${userId}`);
  }
}

export const rolesServices = new RolesServices();
