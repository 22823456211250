import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ToggleButton extends Vue {

  @Prop()
  value: boolean;

  @Prop({default: false})
  disabled: boolean;

  @Prop()
  id: string;

  uuid: string = null;

  created() {
   this.uuid = `${Math.random()*10000000}_${new Date().getUTCMilliseconds()}`;
  }

  get Value() {
    return this.value;
  }
  
  set Value(v) {
    this.$emit('input', v);
  } 
}