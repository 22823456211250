import Vue from "vue";
import { IProjectableModel } from "vue-mf-module/dist/helpers/Projector";
import { Component, Prop } from "vue-property-decorator";

import { usersService } from "@/modules/User/services/usersService";
import user from "@/modules/User/backOffice/components/user/user.vue";

@Component({
  components: {
    user,
  },
})
export default class UserSelectorModal extends Vue {

  @Prop()
  value: IProjectableModel<user.summary[]>;

  filter: string = "";
  users: user.summary[] = [];

  get filteredUsers() {
    return this.users.filter(u => u.userName.indexOf(this.filter) >= 0 || u.email.indexOf(this.filter) >= 0)
      .sort((a, b) => a.userName.toLowerCase() == b.userName.toLowerCase() ? 0 : a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1)
  }

  async mounted() {
    await this.loadData();

    if (this.value && this.value.data == null) this.value.data = [];
  }

  toggleUser(u: user.summary) {
    if (!this.isSelected(u))
      this.value?.data?.push({ userName: u.userName, email: u.email, id: u.id });
    else {

      var idx = this.value?.data?.findIndex(i => i.userName == u.userName);
      if (idx >= 0) this.value?.data?.splice(idx, 1);
    }
  }

  isSelected(u: user.summary) {
    return this.value?.data?.find(i => i.userName == u.userName);
  }

  async loadData() {
    this.users = await usersService.getUsers();
  }

  confirm() {
    this.value.resolve(this.value.data);
  }

  close() {
    this.value.reject();
  }
}
