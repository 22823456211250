import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Document from '@tiptap/extension-document'
import Text from '@tiptap/extension-text'
import Code from '@tiptap/extension-code'
import { ColorHighlighter } from '@/utils/colors'

@Component({
  components: { EditorContent }
})
export default class TextEditor extends Vue {

  @Prop()
  value: string;

  @Prop({ default: false })
  disabled: boolean;

  get Value() { return this.value; }
  set Value(v) { this.$emit('input', v); }

  editor: Editor = null;

  @Watch('disabled')
  disablechanged(n,o){
    if(n != o){
      this.editor.setEditable(!n);
    }
  }

  async mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Highlight,
        Typography,
        Document,
        Text,
        Code,
        ColorHighlighter
      ], content: this.Value
    });
    this.editor.setEditable(!this.disabled);

    this.editor.on("update", (ee) => {
      if(this.disabled) return;
      this.Value = ee.editor.getHTML();
    });
  }

  async beforeDestroy() {
    this.editor.destroy();
  }
}