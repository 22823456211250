import { ActionTree, GetterTree } from "vuex";

export interface AppStoreModel {
  sidebarOpen: boolean
}

export interface AppStoreGetters {
  sidebar: () => boolean
}

export interface AppStoreActions {
  ToggleSideBar(withoutAnimation: boolean);
  CloseSideBar(withoutAnimation: boolean);
}

export const AppStore = {
  PREFIX: "app",
  namespaced: true,
  state: {
    sidebarOpen: true
  },
  getters: {
    sidebar: (state: AppStoreModel) => (): boolean => state.sidebarOpen,
  } as GetterTree<AppStoreModel, AppStoreModel>,
  mutations: {
    TOGGLE_SIDEBAR(state: AppStoreModel) {
      state.sidebarOpen = !state.sidebarOpen
    },
    CLOSE_SIDEBAR(state: AppStoreModel) {
      state.sidebarOpen = false
    }
  },
  actions: {
    ToggleSideBar({ commit }) {
      commit("TOGGLE_SIDEBAR")
    },
    CloseSideBar({ commit }) {
      commit("CLOSE_SIDEBAR")
    }
  } as ActionTree<AppStoreModel, any>,
}


