import Vue from 'vue';
import PopupManager from '../../utils/popup-manager';
import merge from '@/utils/merge';

import ModalEl from './modal.vue';
import { ModalInjectComponent, ModalOptions } from './@types/modal';
import i18n from '@/lang';

let instance;
let instances = [];
let seed = 1;

const Modal = (options: ModalOptions) => {
    if (Vue.prototype.$isServer) return;
    options = merge({}, options);
    const userOnClose = options.onClose;
    const id = 'modal_' + seed++;
    const container = options.container || 'top-center';

    options.onClose = () => {
        Modal.close(id, userOnClose);
    };

    instance = new (Vue.extend(ModalEl))({
        propsData: options,
        i18n: i18n
    });

    instance.id = id;

    instance.$mount();
    document.body.appendChild(instance.$el);

    instance.visible = true;
    instance.dom = instance.$el;
    instance.dom.style.zIndex = PopupManager.nextZIndex();
    instances.push(instance);
    return instance;
};

Modal.close = (id, userOnClose) => {
    let index = -1;
    const len = instances.length;
    const instance = instances.filter((instance, i) => {
        if (instance.id === id) {
            index = i;
            return true;
        }
        return false;
    })[0];
    if (!instance) return;

    if (typeof userOnClose === 'function') {
        userOnClose(instance);
    }
    instances.splice(index, 1);
};

Modal.closeAll = () => {
    for (let i = instances.length - 1; i >= 0; i--) {
        instances[i].close();
    }
};

Modal.alert = (title: string, content: string | ModalInjectComponent, options: ModalOptions) => {
    return Modal(merge({
        title: title,
        content: content,
        classList: 'alert-modal',
        buttonCloseTxt: 'Chiudi'
    } as ModalOptions, options));
};

Modal.confirm = (title, content: string | ModalInjectComponent, options: ModalOptions) => {
    return Modal(merge({
        title: title,
        content: content,
        classList: 'popconfirm-modal',
        buttonConfirmTxt: 'Ok',
        onConfirm(model?) { },
        buttonCloseTxt: 'Annulla'
    } as ModalOptions, options));
};

export default Modal;
