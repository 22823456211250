import Component from "vue-class-component"
import Vue from "vue"

@Component({})
export default class DropDown extends Vue {

  isOpen: boolean = false;

  horizontalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return bb.x - 200 + bb.width;
  }

  verticalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return bb.y + bb.height;
  }
}