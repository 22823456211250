import Vue from 'vue';
import Vuex from 'vuex';
import { CreateTypedStoreProxy } from "vuex-typed-store";
import { AppStore, AppStoreActions, AppStoreGetters, AppStoreModel } from './app';
import { AuthStore, AuthStoreActions, AuthStoreGetters, AuthStoreModel } from './auth.store';
import { permissionStore as PermissionStore, PermissionStoreActions, PermissionStoreGetters, PermissionStoreModel } from './permission.store';

Vue.use(Vuex)

export interface IRootState {
  app: AppStoreModel
  auth: AuthStoreModel
  permission: PermissionStoreModel
}

export interface IRootActions {
  app: AppStoreActions
  auth: AuthStoreActions
  permission: PermissionStoreActions
}

export interface IRootGetters {
  app: AppStoreGetters
  auth: AuthStoreGetters
  permission: PermissionStoreGetters
}

// Declare empty store first, dynamically register all modules later.
export const vueStore = new Vuex.Store({
  modules: {
    [AppStore.PREFIX]: AppStore,
    [AuthStore.PREFIX]: AuthStore,
    [PermissionStore.PREFIX]: PermissionStore
  }
})

export const store = CreateTypedStoreProxy<
  IRootState,
  IRootActions,
  IRootGetters
>(vueStore);