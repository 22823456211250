import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'

import FrontOfficeLayout from "@/layout/frontOffice/frontOffice.vue";
import BackOfficeLayout from "@/layout/backOffice/backOffice.vue";

Vue.use(VueRouter)
export function constantRoutes(): RouteConfig[] {
  return [
    {
      path: '/anonymous',
      component: FrontOfficeLayout,
      redirect: '/login',
      meta: { hidden: true },
      children: [{
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '@/modules/Accounting/views/login.vue'),
        meta: {
          title: `Accedi`,
          hidden: true
        }
      },
      {
        path: '/login/credenziali',
        name: 'loginCredenziali',
        component: () => import(/* webpackChunkName: "login" */ "@/modules/Accounting/views/login-with-credential.vue"),
        meta: {
          title: `Accesso con Credenziali`,
          hidden: true
        }
      }]
    },
    {
      name: 'main',
      path: '/',
      component: FrontOfficeLayout,
      redirect: '/',
      children: [
        {
          component: () => import(/* webpackChunkName: "home" */ '@/views/frontOffice/home.vue'),
          path: '/',
          name: 'home'
        },
        {
          path: '/404',
          name: '404',
          component: () => import(/* webpackChunkName: "404" */ '@/views/error-page/404.vue')
        },
        {
          path: '/401',
          name: '401',
          component: () => import(/* webpackChunkName: "401" */ '@/views/error-page/401.vue')
        }
      ]
    },
  ]
};

export function permissionRoutes(): RouteConfig[] {
  return [
    {
      name: 'profilo',
      path: '/profilo',
      component: FrontOfficeLayout,
      redirect: '/profilo/le-tue-istanze',
      children: [
        {
          path: '/profilo/le-tue-istanze',
          name: 'profilo/le-tue-istanze',
          component: () => import(/* webpackChunkName: "profile" */ '@/modules/User/frontOffice/views/profilo.vue')
        }],
      meta: {
        claims: ['ISTANZE.ALL.CanRead']
      }
    },
    {
      name: 'istanze',
      path: '/istanze',
      component: FrontOfficeLayout,
      children: [
        {
          path: '/istanze/:id',
          name: 'istanze-detail',
          component: () => import(/* webpackChunkName: "backoffice-istanze" */ '@/modules/Istanze/frontOffice/views/detail.vue'),
          props: true,
          meta: {
            title: 'Dettaglio istanza',
            icon: 'fas fa-tachometer-alt',
            hidden: true
          }
        }],
      meta: {
        claims: ['ISTANZE.ALL.CanRead']
      }
    },
    {
      path: '/zone-non-metanizzate',
      component: FrontOfficeLayout,
      redirect: '/zone-non-metanizzate/wizard',
      children: [{
        path: '/zone-non-metanizzate/wizard',
        name: 'znm-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/ZoneNonMetanizzate/add.vue"),
      },
      {
        path: '/zone-non-metanizzate/wizard/:istanzaId',
        name: 'znm-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/ZoneNonMetanizzate/add.vue"),
      },
      {
        path: '/zone-non-metanizzate/wizard/:istanzaId/:cloned',
        name: 'znm-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/ZoneNonMetanizzate/add.vue"),
      }
      ],
      meta: {
        claims: ['ISTANZE.ZNM.CanCreate']
      }
    },
    {
      path: '/certificazione-destinazione-urbanistica',
      component: FrontOfficeLayout,
      redirect: '/certificazione-destinazione-urbanistica/wizard',
      props: true,
      children: [
        {
          path: '/certificazione-destinazione-urbanistica/wizard',
          name: 'cdu-wizard',
          component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/CertificazioneDestinazioneUrbanistica/add.vue"),
        },
        {
          path: '/certificazione-destinazione-urbanistica/wizard/:istanzaId',
          name: 'cdu-wizard',
          props: true,
          component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/CertificazioneDestinazioneUrbanistica/add.vue"),
        }
        ,
        {
          path: '/certificazione-destinazione-urbanistica/wizard/:istanzaId/:cloned',
          name: 'cdu-wizard',
          props: true,
          component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/CertificazioneDestinazioneUrbanistica/add.vue"),
        }
      ],
      meta: {
        claims: ['ISTANZE.CDU.CanCreate']
      }
    },
    {
      path: '/specchio',
      component: FrontOfficeLayout,
      redirect: '/specchio/wizard',
      children: [{
        path: '/specchio/wizard',
        name: 'specchio-wizard',
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/Specchi/add.vue"),
      },
      {
        path: '/specchio/wizard/:istanzaId',
        name: 'specchio-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/Specchi/add.vue"),
      },
      {
        path: '/specchio/wizard/:istanzaId/:cloned',
        name: 'specchio-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/Specchi/add.vue"),
      }
      ],
      meta: {
        claims: ['ISTANZE.SPECCHI.CanCreate']
      }
    },
    {
      path: '/scavo',
      component: FrontOfficeLayout,
      redirect: '/scavo/wizard',
      children: [{
        path: '/scavo/wizard',
        name: 'scavo-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/Scavi/add.vue"),
      },
      {
        path: '/scavo/wizard/:istanzaId',
        name: 'scavo-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/Scavi/add.vue"),
      },
      {
        path: '/scavo/wizard/:istanzaId/:cloned',
        name: 'scavo-wizard',
        props: true,
        component: () => import(/* webpackChunkName: "dashboard" */ "@/modules/Scavi/add.vue"),
      }
      ],
      meta: {
        claims: ['ISTANZE.SCAVI.CanCreate']
      }
    },
    {
      name: 'backoffice',
      path: '/back-office',
      component: BackOfficeLayout,
      redirect: '/back-office/dashboard',
      children: [
        {
          path: '/back-office/dashboard',
          name: 'backoffice-dashboard',
          component: () => import(/* webpackChunkName: "backoffice-dashboard" */ '@/views/backOffice/dashboard.vue'),
          meta: {
            claims: ['ISTANZE.ALL.CanReadAll', 'USERS.enabled', 'GROUPS.enabled']
          }
        }, {
          path: '/back-office/istanze',
          name: 'backoffice-istanze',
          component: () => import(/* webpackChunkName: "backoffice-istanze" */ '@/modules/Istanze/backOffice/views/index.vue'),
          props: {
            take: 10,
          },
          meta: {
            claims: ['ISTANZE.ALL.CanReadAll']
          }
        }, {
          path: '/back-office/istanze/:id',
          name: 'backoffice-istanze-detail',
          component: () => import(/* webpackChunkName: "backoffice-istanze" */ '@/modules/Istanze/backOffice/views/detail.vue'),
          props: true,
          meta: {
            claims: ['ISTANZE.ALL.CanReadAll']
          }
        }, {
          path: "/back-office/users",
          name: "backoffice-users",
          component: () => import(/* webpackChunkName: "users" */ "@/modules/User/backOffice/views/UsersView/UsersView.vue"),
          props: {
            nogroups: false,
            type: null,
          },
          meta: {
            claims: ['USERS.enabled']
          }
        },
        {
          path: "/back-office/roles",
          name: "backoffice-roles",
          component: () => import(/* webpackChunkName: "users" */ "@/modules/User/backOffice/views/RolesView/RolesView.vue"),
          props: {
            nogroups: false,
            type: null,
          },
          meta: {
            claims: ['GROUPS.enabled']
          }
        },
        {
          path: "/back-office/teams",
          name: "backoffice-teams",
          component: () => import(/* webpackChunkName: "users" */ "@/modules/User/backOffice/views/TeamsView/TeamsView.vue"),
          props: {
            nogroups: false,
            type: null,
          },
          meta: {
            claims: ['TEAMS.enabled']
          }
        },
        {
          path: "/back-office/workspaces",
          name: "backoffice-workspaces",
          component: () => import(/* webpackChunkName: "users" */ "@/modules/User/backOffice/views/WorkspacesView/WorkspacesView.vue"),
          props: {
            nogroups: false,
            type: null,
          },
          meta: {
            claims: ['WORKSPACE.enabled']
          }
        },
        {
          path: "/back-office/configurazione/protocollazione",
          name: "configurazione-protocollazione",
          component: () => import(/* webpackChunkName: "users" */ "@/modules/Protocollazione/views/indexAttributes.vue"),
          props: true,
          meta: {
            claims: ['ISTANZE.ALL.CanReadAll']
          }
        },
        {
          path: '/back-office/configurazione/protocollazione/:id',
          name: 'configurazione-protocollazione-detail',
          component: () => import(/* webpackChunkName: "backoffice-istanze" */ '@/modules/Protocollazione/views/detail.vue'),
          props: true,
          meta: {
            claims: ['ISTANZE.ALL.CanReadAll']
          }
        },
        {
          path: "/back-office/configurazione/layer",
          name: "configurazione-layer",
          component: () => import(/* webpackChunkName: "users" */ "@/modules/Layer/views/indexLayers.vue"),
          props: true,
          meta: {
            claims: ['LAYERS.ALL.CanRead']
          }
        }, {
          path: '/back-office/configurazione/layer/:id',
          name: 'backoffice-configurazione-layer-detail',
          component: () => import(/* webpackChunkName: "backoffice-istanze" */ '@/modules/Layer/views/detail.vue'),
          props: true,
          meta: {
            claims: ['LAYERS.ALL.CanRead']
          }
        }],
      meta: {
        claims: ['ISTANZE.ALL.CanReadAll', 'USERS.enabled', 'GROUPS.enabled']
      }
    }
  ]
};

const createRouter = () =>
  new VueRouter({
    mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: constantRoutes()
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
