import { Vue, Component } from "vue-property-decorator";

@Component
export default class Cookies extends Vue {

  get cookieAcceptance(): boolean {
    if (localStorage.getItem("cookieAcceptance"))
      return JSON.parse(localStorage.getItem("cookieAcceptance"))
    return false;
  }

  set cookieAcceptance(v: boolean) {
      localStorage.setItem("cookieAcceptance", JSON.stringify(v))
      document.getElementById("cookiebar").classList.remove("show");
  }
}
