import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";


@Component({})
export default class GroupItem extends Vue {

  @Prop()
  value: string;

  @Prop({ default: null })
  title: string;
}