import { Vue, Component } from 'vue-property-decorator';
import { store } from '@/store/index';
import { authService } from '@/services/authService';

@Component({})
export default class MainHeaderAccounting extends Vue {
  get userinfo(): user.item {
    return store.getters.auth.userInfo();
  }

  login() {
    if (this.userinfo?.fiscalCode) {
      if (this.$route.name != "/cassettocittadino/home")
        this.$router.push({ name: "/cassettocittadino/home" })
    }
    else {
      if (this.$route.name != "accesso/sceltaaccesso")
        this.$router.push({ name: "accesso/sceltaaccesso" })
    }
  }

  async logout() {
    await authService.Logout();
    this.$router.push("/");
  }

  get username(): string {
    if (!this.userinfo) return null;
    if (this.userinfo.firstName && this.userinfo.lastName) {
      return `${this.userinfo.firstName} ${this.userinfo.lastName}`
    }
    return this.userinfo.userName;
  }
}