import { Vue, Component } from 'vue-property-decorator';

import MainHeaderLangs from './main-header-langs.vue';
import MainHeaderAccounting from './main-header-accounting.vue';
import MainHeaderNavbar from './main-header-navbar.vue';

@Component({
  components: {
    MainHeaderLangs,
    MainHeaderAccounting,
    MainHeaderNavbar
  }
})
export default class MainHeader extends Vue { }