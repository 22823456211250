import Vue from "vue";
import { RouteConfig } from "vue-router";
import Vuex, { ActionTree, GetterTree } from "vuex";

import { constantRoutes, permissionRoutes } from '@/router';

Vue.use(Vuex);
const hasPermission = (claims: string[], route: RouteConfig) => {
  if (route.meta && route.meta.claims && route.meta.claims.length > 0) {
    return claims.some(role => route.meta.claims.includes(role));
  } else {
    return true;
  }
};

export const filterAsyncRoutes = (routes: RouteConfig[], claims: string[]) => {
  const res: RouteConfig[] = [];
  routes.forEach(route => {
    const r = { ...route };
    if (hasPermission(claims, r)) {
      if (r.children) {
        r.children = filterAsyncRoutes(r.children, claims);
      }
      res.push(r);
    }
  });
  return res;
};

export interface PermissionStoreActions {
  generateRoutes(claims: string[]);
  setLoadedPermissionRouteClaims(value: boolean)  
}
export interface PermissionStoreGetters {
  routes: () => RouteConfig[];
  dynamicRoutes: () => RouteConfig[];
  loadedPermissionRouteClaims:() => boolean;
}
export interface PermissionStoreModel {
  loadedPermissionRouteClaims: boolean;
  routes: RouteConfig[];
  dynamicRoutes: RouteConfig[];
}

export const permissionStore = {
  PREFIX: "permission",
  namespaced: true,
  state: {
    loadedPermissionRouteClaims: false,
    routes: [],
    dynamicRoutes: []
  } as PermissionStoreModel,
  getters: {
    routes: (state: PermissionStoreModel) => (): RouteConfig[] => state.routes,
    dynamicRoutes: (state: PermissionStoreModel) => (): RouteConfig[] => state.dynamicRoutes,
    loadedPermissionRouteClaims: (state: PermissionStoreModel) => (): boolean => state.loadedPermissionRouteClaims

  } as GetterTree<PermissionStoreModel, PermissionStoreModel>,
  mutations: {
    SET_ROUTES(state: PermissionStoreModel, routes: RouteConfig[]) {
      state.routes = constantRoutes().concat(routes);
      state.dynamicRoutes = routes;
    },
    SET_LOADED(state: PermissionStoreModel, value: boolean) {
      state.loadedPermissionRouteClaims = value;
    }
  },
  actions: {
    generateRoutes(context, roles: string[]) {
      const accessedRoutes = filterAsyncRoutes(permissionRoutes(), roles);
      context.commit('SET_ROUTES', accessedRoutes);
    },
    setLoadedPermissionRouteClaims(context, value: boolean){
      context.commit('SET_LOADED', value);
    }
  } as ActionTree<PermissionStoreModel, any>,
};



