import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'

const languageKey = 'language'
export const getLanguage = () => Cookies.get(languageKey)
export const setLanguage = (language: string) => Cookies.set(languageKey, language)

// User defined lang
import itLocale from './it'
import userManagment from './userManagment'
import claims from './claims'


Vue.use(VueI18n)

const messages = {

  it: {
    ...itLocale,
    ...userManagment,
    ...claims
  }
}

export const getLocale = () => {
  const cookieLanguage = getLanguage()
  if (cookieLanguage) {
    document.documentElement.lang = cookieLanguage
    return cookieLanguage
  }

  const language = navigator.language.toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      document.documentElement.lang = locale
      return locale
    }
  }

  // Default
  return 'it'
}

const i18n = new VueI18n({
  locale: getLocale(),
  messages
})

export default i18n
