import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class UsersService extends baseRestService {
  constructor() {
    super();
    this.baseUrl = () => `${settings.authService.endpoint}/identity/`;
  }

  public async getUsers(): Promise<user.summary[]> {
    return await this.Get<user.summary[]>("users");
  }

  public async getUser(id: string): Promise<user.item> {
    return await this.Get<user.item>(`users/${id}`);
  }

  public async createUser(user: user.item): Promise<user.item> {
    return await this.Post<user.item>(`users`, user);
  }

  public async updateUser(user: user.item): Promise<user.item> {
    return await this.Put<user.item>(`users/${user.id}`, user);
  }

  public async lockUser(user: user.summary | user.item): Promise<void> {
    return await this.Post(`users/${user.id}/lock`, {});
  }

  public async unlockUser(user: user.summary | user.item): Promise<void> {
    return await this.Post(`users/${user.id}/unlock`, {});
  }

  public async resetPassword(user: user.summary | user.item, newpassword: string): Promise<void> {
    return await this.Post(`users/${user.id}/resetpassword`, JSON.stringify(newpassword));
  }

  public async changePassword(user: user.summary | user.item, oldpassword: string, newpassword: string): Promise<void> {
    return await this.Post(`users/${user.id}/changepassword`, {}, { oldpassword, newpassword });
  }

  public validatePassword(passwd: string, confirmPassword: string) {
    let result = {
      length: passwd.length >= 6,
      digit: /\d/.test(passwd),
      lowercase: /[a-z]/.test(passwd),
      uppercase: /[A-Z]/.test(passwd),
      special: /[^a-zA-Z0-9]/.test(passwd),
      equals: passwd == confirmPassword,
      isValid: false,
    };

    result.isValid = result.digit && result.length && result.lowercase && result.special && result.uppercase && result.equals;
    return result;
  }

  public async getUserRoles(user: user.summary | user.item): Promise<Array<string>> {
    return await this.Get(`users/${user.id}/roles`);
  }

  public async getMyRoles(): Promise<string[]> {
    return await this.Get<string[]>(`/users/roles`);
  }

  public getUserColor(username: string, opacity: number = 1): string {
    return '';
  }
}

export const usersService = new UsersService();
