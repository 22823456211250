import Vue from "vue";
import Vuex, { ActionTree, GetterTree } from "vuex";
Vue.use(Vuex);

export interface AuthStoreActions {
  setMyUserInfo(model: user.item);
  setUsers(model: user.summary[]);
  setClaims(model: string[]);
}
export interface AuthStoreGetters {
  userInfo: () => user.item
  codiceFiscale: () => string
  hasPermission: (id: string) => boolean
  getUserSummary(id): user.summary
}
export interface AuthStoreModel {
  userInfo: user.item,
  claims: string[],
  users: user.summary[],
}

export const AuthStore = {
  PREFIX: "auth",
  namespaced: true,
  state: {
    userInfo: null,
    claims: [],
    users: [],
  } as AuthStoreModel,
  getters: {
    userInfo: (state: AuthStoreModel) => (): user.item => state.userInfo,
    codiceFiscale: (state: AuthStoreModel) => (): string => state.userInfo?.fiscalCode,
    hasPermission: (state: AuthStoreModel) => (id): boolean => !!state.claims.find(c => c == id),
    getUserSummary: (state) => (id): user.summary => state.users.find(i => i.id == id),
  } as GetterTree<AuthStoreModel, AuthStoreModel>,
  mutations: {
    SET_MyUserInfo(state: AuthStoreModel, model: user.item) {
      state.userInfo = model;
    },
    SET_USERS(state: AuthStoreModel, model: user.summary[]) {
      state.users.splice(0, state.users.length);
      state.users.push(...model)
    },
    SET_CLAIMS(state: AuthStoreModel, model: string[]) {
      state.claims = model;
    }
  },
  actions: {
    setMyUserInfo({ commit }, model: user.item) {
      commit("SET_MyUserInfo", model)
    },
    setUsers({ commit }, model: user.summary[]) {
      commit("SET_USERS", model);
    },
    setClaims({ commit }, model: string[]) {
      commit("SET_CLAIMS", model)
    }
  } as ActionTree<AuthStoreModel, any>,
};



