import { Vue, Component } from 'vue-property-decorator';
import { store } from '@/store';

@Component({})
export default class MainSidebar extends Vue {
  get isCollapse() {
    return !store.getters.app.sidebar();
  }

  get routes() {
    return store.getters.permission.routes();
  }

  activePath(path: string) {
    return this.$route.path.startsWith(path);
  }
}
