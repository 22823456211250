import Vue, { VNode, VueConstructor } from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import { NotificationPosition, NotificationType } from './@types/notification';
@Component({})
export default class NotificationEl extends Vue {
    @Prop({ required: true })
    title?: string;

    @Prop({ default: null })
    message: string | VNode | VueConstructor<Vue>

    @Prop({ default: 'no' })
    type: NotificationType

    @Prop({ default: 'bottom-right' })
    position: NotificationPosition

    @Prop({ default: true })
    dismissable: boolean;

    @Prop({ default: 5000 })
    duration: number;

    @Prop({ default: () => { return } })
    onClose?: () => void

    closed: boolean = false;
    timer: NodeJS.Timeout = null;
    visible: boolean = false;
    id: string = null;

    mounted() {
        this.startTimer();
        document.addEventListener('keydown', this.keydown);
    }

    beforeDestroy() {
        document.removeEventListener('keydown', this.keydown);
    }

    get horizontalClass() {
        if (this.position.indexOf('left') > -1)
            return 'left';
        if (this.position.indexOf('center') > -1)
            return 'center';
        return 'right';
    }

    get verticalProperty() {
        return /^top-/.test(this.position) ? 'top' : 'bottom';
    }

    get positionStyle() {
        return {
            [this.verticalProperty]: `${this.verticalOffset}px`
        };
    }

    get icon(): string {
        switch (this.type) {
            case 'success':
                return 'it-check-circle';
            case 'error':
                return 'it-close-circle';
            case 'info':
                return 'it-info-circle';
            case 'warning':
                return 'it-error';
            default: return null;
        }
    }

    @Watch("closed")
    closedChange(newVal) {
        if (newVal) {
            this.visible = false;
            this.$el.addEventListener('transitionend', this.destroyElement);
        }
    }

    destroyElement() {
        this.$el.removeEventListener('transitionend', this.destroyElement);
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    }

    close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
            this.onClose();
        }
    }

    clearTimer() {
        clearTimeout(this.timer);
    }

    startTimer() {
        if (this.duration > 0) {
            this.timer = setTimeout(() => {
                if (!this.closed) {
                    this.close();
                }
            }, this.duration);
        }
    }

    verticalOffset: number = 0;

    handleAfterLeave() {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    }

    keydown(e) {
        if (e.keyCode === 27) { // esc关闭消息
            if (!this.closed) {
                this.close();
            }
        }
    }
}