import settings from '@/settings';
import { baseRestService } from '@/services/_base/baseRestService'

class TeamsServices extends baseRestService {

  constructor() {
    super();
    this.baseUrl = () => `${settings.authService.endpoint}/identity/teams`;
  }

  public async getTeams(): Promise<Array<role.team>> {
    return await this.Get<Array<role.team>>("");
  }

  public async createNewTeam(teamName: string): Promise<void> {
    await this.Post(`/${teamName}`, {});
  }

  public async deleteTeam(teamName: string): Promise<void> {
    await this.Delete(`/${teamName}`);
  }

  public async getTeamUsers(teamName: string): Promise<Array<user.summary>> {
    return await this.Get(`/${teamName}/users`);
  }

  public async assignTeamToUser(teamName: string, userId: string): Promise<void> {
    await this.Post(`/${teamName}/user/${userId}`, {});
  }

  public async removeUserFromTeam(teamName: string, userId: string): Promise<void> {
    await this.Delete(`/${teamName}/user/${userId}`);
  }
}

export const teamsServices = new TeamsServices();
