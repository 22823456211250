import axios from "axios";

declare const PRODUCTION;

interface IAuthService {
  scheme: string,
  clientId: string,
  scopes: string,
  endpoint: string,
  refreshTokenInterval: number
}
interface IEsriService {
  endpoint: string
}
interface tipiDebito {
  CDU: string,
  Scavi: string,
  Specchi: string
}

interface PagoPaConfiguration {
  pagoPAEnable: boolean
  tipoDebito: tipiDebito
}
interface ISettings {
  baseUrl: string,
  portalUrl: string,
  authService: IAuthService,
  esriService: IEsriService,
  digitalSignatureMandatory: boolean,
  pagoPaConfiguration: PagoPaConfiguration,
  homeRoutes: { path: string, name: string, description?: string }[]
}

// You can customize settings below :)
const settings: ISettings = {
  baseUrl: 'https://localhost:55536',
  portalUrl: 'https://maps.comune.perugia.it/portal',
  authService: {
    scheme: 'pgSpid',
    endpoint: 'https://localhost:55536',
    clientId: 'sit_g478',
    scopes: 'openid profile email offline_access',
    refreshTokenInterval: 5
  },
  esriService: {
    endpoint: 'https://localhost:55536'
  },
  digitalSignatureMandatory: true,
  pagoPaConfiguration: {
    pagoPAEnable: true,
    tipoDebito: {
      CDU: "CDU_CERT_DESTINAZ_URB",
      Scavi: "SCAVI_SUOLO_PUBBLICO",
      Specchi: "SPECCHI_PARABOLICI"
    }
  },
  homeRoutes: [
    // { path: '/scavo', name: 'Scavi stradali', description: null },
    // { path: '/specchio', name: 'Specchi parabolici', description: null },
    // { path: '/certificazione-destinazione-urbanistica', name: 'Certificazione destinazione urbanistica', description: null },
    { path: '/zone-non-metanizzate', name: 'Zona non metanizzata', description: null }
  ]
}


export async function loadSettings() {

  let response = null;

  try {
    response = await axios.get("/settings.custom.json");
    Object.assign(settings, response.data);
  } catch { }

  if (!response)
    try {
      response = await axios.get("/settings.json");
      Object.assign(settings, response.data);
    } catch { }

  try {
    if (PRODUCTION !== undefined && PRODUCTION) {
      console.error("NO CONFIGURATION FOUND !!", "please provide settings.json or settings.custom.json file !")
    }
  } catch { }

}



export default settings
