import Vue, { VNode, VueConstructor } from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";
import { ModalInjectComponent, ModalSize, ModalType } from '../modal/@types/modal';
import { Modal } from 'bootstrap-italia'

@Component({})
export default class ModalEl extends Vue {
    @Prop({ default: "" })
    title?: string;

    @Prop({ default: null })
    content: ModalInjectComponent

    @Prop({ default: null })
    type?: ModalType

    @Prop({ default: null })
    size?: ModalSize

    @Prop({ default: null })
    classList: string

    @Prop({ default: true })
    dismissable: boolean;

    @Prop({ default: 'Conferma' })
    buttonConfirmTxt: string

    @Prop({ default: (model?: any) => { } })
    onConfirm?: (model?: any) => void | Promise<void>;

    @Prop({ default: 'Chiudi' })
    buttonCloseTxt: string

    @Prop({ default: () => { } })
    onClose?: () => void

    @Prop({ default: 'static' })
    backdrop: boolean | string;

    @Prop({ default: false })
    keyboard: boolean

    @Prop({ default: true })
    focus: boolean

    closed: boolean = false;
    visible: boolean = false;
    id: string = null;

    modal: any = null;
    mounted() {
        document.addEventListener('keydown', this.keydown);

        const modalEL = this.$refs[this.id];
        this.modal = new Modal(modalEL, {
            keyboard: this.keyboard,
            backdrop: this.backdrop,
            focus: this.focus
        });
        this.modal.show();
    }

    beforeDestroy() {
        document.removeEventListener('keydown', this.keydown);
    }

    get isComponent() {
        return !(typeof (this.content) === "string");
    }

    get icon(): string {
        switch (this.type) {
            case 'success':
                return 'it-check-circle';
            case 'danger':
                return 'it-close-circle';
            case 'info':
                return 'it-info-circle';
            case 'warning':
                return 'it-error';
            default: return null;
        }
    }

    @Watch("closed")
    closedChange(newVal) {
        if (newVal) {
            this.visible = false;
            this.$el.addEventListener('transitionend', this.destroyElement);
            this.modal.hide()
        }
    }

    destroyElement() {
        this.$el.removeEventListener('transitionend', this.destroyElement);
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    }

    confirm() {
        this.closed = true;
        if (typeof this.onConfirm === 'function') {
            if (this.isComponent) {
                this.onConfirm((this.content as ModalInjectComponent).model);
            } else {
                this.onConfirm();
            }
        }
    }

    close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
            this.onClose();
        }
    }

    handleAfterLeave() {
        this.$destroy();
        this.$el.parentNode.removeChild(this.$el);
    }

    keydown(e) {
        if (e.keyCode === 27) { // esc关闭消息
            if (!this.closed) {
                this.close();
            }
        }
    }
}