export default {
  route: {
    dashboard: `Dashboard`,
  },
  navbar: {
    logOut: `Esci`,
    dashboard: `Dashboard`,
    github: `Github`,
    theme: `Tema`,
    size: `Grandezza Globale`,
    profile: `Profilo`
  },
  users: {
    search: `Cerca`,
    accountLabel: `Account:`,
    createUserTitle: `Inserisci utente`,
    createUserLogin: `Login`,
    createUserLoginTip: `Inserisci Login`,
    createUserEmail: `Email`,
    createUserPhone: `Numero di telefono`,
    createUserFiscalCode: `Codice Fiscale`,
    userExistsTitle: `Attenzione`,
    userExistsBody: `Utente Esistente`,
    updateUserButton: `Aggiorna`,
    removeUserButton: `Elimina`,
  },
  errorPage: {
    back: `Torna alla Home`,
    401: {
      title: `Non sei autorizzato ad accedere`,
      description: `Siamo spiacenti, l'accesso alla pagina richiesta con le sue credenziali è stato negato.`
    },
    404: {
      title: `Pagina non trovata`,
      description: `Siamo spiacenti, ma non è stato possibile trovare la pagina richiesta.`
    }
  },
  istanze: {
    tipologiaAllegati: {
      EstremiImpresaEsecutrice: "Estremi dell’Impresa Esecutrice",
      NominaDirettoreLavori: "Nomina ed accettazione del Direttore dei Lavori",
      Cronoprogramma: "Cronoprogramma dei Lavori, valido anche per determinare i giorni di occupazione temporanea del suolo",
      RelazioneTecnica: "Relazione   tecnica contenente   la   descrizione  dell’intervento,isottoservizi interessati (Gas, Acquedotto, Fognatura, Telecom, Enel od altro), le  indicazioni  delle  dimensioni  dello  scavola necessità di eventuali limitazioni o sospensioni del traffico viario da disciplinare successivamente con apposita Ordinanza ",
      RilievoFotografico: "Rilievo fotografico della zona interessata dalla manomissione",

      EstrattoMappaCatastale: "Estratto di mappa catastale in scala 1:1000/2000",
      Planimetria: "Planimetria in scala 1:100/200 con dettaglio degli scavi longitudinali e trasversali",
      SezioneStrada: "Sezione completa della strada con il posizionamento dei sottoservizi in scala 1:50/100",
      ComputoMetrico: "Computo metrico estimativo delle opere di ripristino del corpo stradale e della pavimentazione",
      CopiaComunicazioneIntervento: "Copia  della  comunicazione  dell’intervento da regolarizzare effettuato in via d’urgenza e trasmesso all’U.O. Mobilità e Infrastrutture ed al Comando VVUU",
      TabellaRiepilogativa: "Tabella riepilogativa del calcolo degli indennizzi (art. 12 Reg.Scavi) e, se dovuti, degli oneri specifici per interventi suopere di recente realizzazione o ristrutturazione (art. 14Reg.Scavi)",
      assolvimentoImpostaDiBollo: "Dichiarazione Sostitutiva Atto di Notorietà per Assolvimento Imposta di Bollo",
      ricevutaPagamentoBollo: "Ricevuta del pagamento dell'Imposta di Bollo",
      delega: "Delega",
      delegaGenerico: "",
      delegaProcuraSpeciale: "Delega Procura Speciale",
      documentoIdentitaRichiedente: "Documento di identità del Richiedente",
      documentoIdentitaDelegante: "Documento di identità del Delegante",
      riferimentiCatastali: "Riferimenti Catastali Relativi a Foglio e Particella",
      rilievoFotografico: "Rilievo Fotografico",
      elaboratiFormatoShapefile: "Elaborati in formato shapefile",
      documentazioneFotografica: "Documentazione Fotografica Aria di intervento",
      ModuloFirmatoDigitalmente: "Modulo Firmato"
    },
    tipologiaAllegatiCorto: {
      EstremiImpresaEsecutrice: "Estremi Impresa Esecutrice",
      NominaDirettoreLavori: "Nomina Direttore Lavori",
      Cronoprogramma: "Cronoprogramma",
      RelazioneTecnica: "Relazione Tecnica",
      RilievoFotografico: "Rilievo Fotografico",

      EstrattoMappaCatastale: "Estratto Mappa Catastale",
      Planimetria: "Planimetria",
      SezioneStrada: "Sezione Strada",
      ComputoMetrico: "Computo Metrico",
      CopiaComunicazioneIntervento: "Copia Comunicazione Intervento",
      TabellaRiepilogativa: "Tabella Riepilogativa",
      assolvimentoImpostaDiBollo: "Assolvimento Imposta di Bollo",
      ricevutaPagamentoBollo: "Ricevuta pagamento Bollo",
      delega: "Delega",
      delegaProcuraSpeciale: "Delega Procura Speciale",
      documentoIdentitaRichiedente: "Documento Richiedente",
      documentoIdentitaDelegante: "Documento Delegante",
      riferimentiCatastali: "Riferimenti Catastali",
      rilievoFotografico: "Rilievo Fotografico",
      elaboratiFormatoShapefile: "Elaborati shapefile",
      documentazioneFotografica: "Documentazione Fotografica",
      ModuloFirmatoDigitalmente: "Modulo Firmato"
    },
    tipologia: {
      ZoneNonMetanizzate: `Zone non Metanizzate`,
      Specchi: `Specchi`,
      Scavi: `Scavi`,
      CDU: `Certificazione Destinazione Urbanistica`,
    },
    tipologiaEsplicita: {
      Specchi: "Installazione di specchio parabolico",
      Scavi: "Esecuzione di scavi su strada pubblica",
      ZoneNonMetanizzate: "Richiesta Attestazione dell’Ubicazione dell’immobile in zona non metanizzata",
      CDU: "Richiesta di certificazione di destinazione urbanistica"
    },
    stato: {
      DaProtocollare: `Da Protocollare`,
      PresentataDomanda: `Presentata Domanda`,
      Istruttoria: `Istruttoria`,
      RichiestaIntegrazione: `Richiesta Integrazione`,
      Integrato: `Integrato`,
      RichiestaPagamento: `Richiesta Pagamento`,
      ValidazionePagamento: `Validazione Pagamento`,
      Pagato: `Pagato`,
      ParereInterno: `Parere Interno`,
      RichiestaFirma: `Richiesta Firma`,
      FirmaEseguita: `Firma Eseguita`,
      Concluso: `Concluso`,
      LavoriIniziati: 'Data Inizio Lavori Comunicata',
      LavoriTerminati: 'Data Fine Lavori Comunicata',
      Bozza: 'Salvata come bozza'
    },
    statoIcona: {
      DaProtocollare: `<svg class="mx-1 icon icon-danger"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-close-circle"></use></svg>`,
      PresentataDomanda: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-close-circle"></use></svg>`,
      Istruttoria: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-close-circle"></use></svg>`,
      RichiestaIntegrazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      Bozza: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      Integrato: `<svg class="mx-1 icon icon-success"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use></svg>`,
      RichiestaPagamento: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      ValidazionePagamento: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      Pagato: `<svg class="mx-1 icon icon-success"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use></svg>`,
      ParereInterno: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      RichiestaFirma: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      FirmaEseguita: `<svg class="mx-1 icon icon-success"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use></svg>`,
      Concluso: `<svg class="mx-1 icon icon-success"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use></svg>`,
      LavoriIniziati: '<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-close-circle"></use></svg>',
      LavoriTerminati: '<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-close-circle"></use></svg>'
    },
    salvataggio: {
      error: 'IMPOSSIBILIRE SALVARE L\'ISTANZA CI SCUSIAMO PER L\'INCONVENIENTE',
    },
    protocollazione: {
      error: 'ISTANZA SALVATA MA NON E\' STATO POSSIBILE PROTOCOLLARLA SI PREGA DI RIPROVARE PIU TARDI',
      success: 'ISTANZA SALVATA E PROTOCOLLATA',
    },
    cambioStato: {
      error: 'NON E\' STATO POSSIBILE CAMBIARE LO STATO DELL\'ISTANZA'
    },
    parereInterno: {
      error: 'NON E\' STATO POSSIBILE PROTOCOLLARE. SI PREGA DI RICONTROLLARE I CAMPI O RIPROVARE PIU TARDI'
    }
  },
  layer: {
    attributi: {
      ZoneNonMetanizzate: "Attributi layer Zone metanizzate",
      Scavi: "Attributi layer Scavi",
      Specchi: "Attributi layer Specchi",
      null: ""
    }
  },
  cdu: {
    tipologia: {
      Compravendita: `Compravendita`,
      Successione: `Successione`,
      Storico: `Storico`
    },
    testoInvio: {
      title: "Presenta domanda",
      content: "Cliccando su conferma si prende atto che la sua istanza verrà sottoposta a protocollazione e inviata al comune"
    },
    storico: {
      DataSpecifica: "Data Specifica",
      Intervallo: "Intervallo"
    }
  },
  znm: {
    civicoNotMatch: "Il civico <i>{civico}</i> inserito <b>non è presente nell'elenco dei civici</b> relativi all'indirizzo <i>{indirizzo}</i>"
  },
  specchio: {

    tipologia: {
      Pubblica: 'CASO A1 - Installazione dello specchio parabolico eseguito dal Comune di Perugia.',
      Ibrida: 'CASO A2 - Installazione dello specchio parabolico eseguito dal Privato, con la fornitura dei materiali da parte del Comune di Perugia.',
      Privata: 'CASO A3 -Installazione dello specchio parabolico eseguito dal Privato, con materiali propri.',
    }
  },
  scavo: {
    tipologiaRichiesta: {
      Autorizzazione: "L’autorizzazione con manomissione di suolo pubblico",
      Regolarizzazione: "La regolarizzazione dell’intervento effettuato in via d’urgenza come  in  precedenza  comunicato  alla  U.O.  Mobilità  e Infrastruttureed al Comando VVUU",
      Proroga: "La proroga dell’autorizzazione/Rinnovo di un'autorizzazione precedente"
    },
    tipologiaClassificazione: {
      InProgramma: "In programma",
      NonInProgramma: "Non in programma",
      UtenzaAllaccio: "Utenza-Allaccio"
    },
    tipologiaRichiestaName: {
      Autorizzazione: "Autorizzazione",
      Regolarizzazione: "Regolarizzazione",
      Proroga: "Proroga/Rinnovo"
    },
    tipologiaIntervento: {
      Stradale: "Stradale",
      NonStradale: "Non Stradale"
    },
    tipologiaInterventoStradale: {
      Carreggiata: "Carreggiata",
      Marciapiede: "Marciapiede",
      Banchina: "Banchina",
      Altro: "Altro"
    },
    tipologiaCondizioni: {
      RegolamentoComunale: "Il richiedente dichiara di  accettare  le  condizioni  e  obblighi  prescritti  dal  Regolamento  Comunale  per  gli  scavi  del  sottosuolo pubblico approvato con Delibera del C.C. n. 188 del 22.11.2010e s.m.i. in ultimoDelibera del C.C. n. 47 del 01.04.2019 ed in particolare le penali indicate nell’art. 11 del medesimo Regolamento.",
      Tosap: "Di conoscere e di accettare tutte le condizioni previste dal Regolamento per l’applicazione del canone patrimoniale di concessione, autorizzazione o esposizione pubblicitaria e del canone per l’occupazione delle aree e degli spazi appartenenti al demanio o al patrimonio indisponibile destinati a mercati (Deliberazioni del Consiglio Comunale n. 51 del 19/04/2021, n. 72 del 31/05/2021, n. 42 del 23/05/2022 e n. 46 del 08/05/2023.",
      UbicazioneServizi: "Il richiedente dichiara di essere in possesso di tutte le informazioni necessarie ubicazione dei servizi a rete presenti nell’area interessata dai lavori",
      Larghezza: "Il richiedente dichiara che la larghezza dello scavo ne consente il riempimento e la costipazione in relazione al materiale utilizzato",
      RispettoNormative: "Il richiedente dichiara che  le  opere  di  scavo,  riempimento,  posa  in  opera  di  manufatti  nonché  tutte  le  opere  provvisionali eventualmente necessarie saranno eseguite nel rispetto delle normative vigenti anche in materia di sicurezza",
      Nullaosta: "Il richiedente dichiara di essere  in  possesso  di tutti i nullaosta, permessi o autorizzazioni eventualmente necessari all’esecuzione dell’opera di competenza di altri uffici comunali o di altri Enti",
      DichiarazioniMendaci: "Il richiedente dichiara di essere consapevole delle responsabilità e delle sanzioni penali stabilite dalla legge per false attestazioni e mendaci dichiarazioni (art. 76 D.P.R. 445/2000) e della decadenza dai benefici eventualmente conseguenti al provvedimento emanato sulla base delle dichiarazioni non veritiere (art. 75 D.P.R. 445/2000).",
      AltraDichiarazione: "Dichiarazione aggiuntiva facoltativa"
    },
    concessioneOccupazioneSottosuoloPermanente: "La concessione d’occupazione di sottosuolo  pubblico  permanente  così  come  meglio  evidenziato  negli allegati alla presente istanza e la concessione di suolo temporaneo per l’esecuzione del cantiere.",
    rifacimentoTappetoComune: "Di avvalersi della possibilità di esecuzione da parte del Comune delle operazioni di rifacimento del tappeto di usura e della segnaletica orizzontale",
    esenzioneBollo: "Il richiedente dichiara di essere esente dall’imposta di bollo ai sensi del D.P.R. 642/1972 e successive modificazioni?"
  },
  allegati: {
    allegati: "Allegati",
    allegatiDelega: "Allegati relativi alla delega",
    list: "Lista di allegati",
    add: "Aggiungi un nuovo allegato",
    dblclick: "Doppio click per eliminare",
    search: "Cerca allegato",
    edit: "Modifica dashboard",
    save: "Salva",
    cancel: "Cancella",
    delete: "Elimina l'allegato",
    title: "Titolo dell'Allegato",
    state: {
      none: "None",
      pending: "Pending",
      processing: "Processing",
      success: "Ready to use",
      fail: "Consolidation fail",
    },
    dataSource: {
      upload: "Carica file",
      browse: "Seleziona file",
      delete: "Cancella file",
      toBeUploaded: "Da caricare",
      uploaded: "Caricato",
      uploadedFiles: "File caricato/i"
    },
    tipologia: {
      CDU: "OBBLIGATORIO: PLANIMETRIA CATASTALE (VAX in scala 1:2000) con evidenziate le particelle oggetto di richiesta",
      Specchi: "Opzionali: Rilievo fotografico e/o riferimenti catastali relativi a foglio e particella.",
      Delega: "OBBLIGATORI: Richiesta istanza FIRMATA dal delegatario, documenti d'identità di Richiedente e Delegante",
      DelegaScavi: "OBBLIGATORI: Delega firmata digitalmente, documenti d'identità di Richiedente e Delegante",
      DelegaCDU: "OBBLIGATORI: Richiesta istanza FIRMATA DIGITALMENTE dal delegatario da caricare sotto la spunta delga, documenti d'identità di Richiedente e Delegante",
      DelegaSpecchi: "OBBLIGATORI: Richiesta istanza FIRMATA DIGITALMENTE dal delegatario da caricare sotto la spunta delga, documenti d'identità di Richiedente e Delegante",
    }
  },
  privacy: {
    link: "https://www.comune.perugia.it/pagine/privacy-policy",
    CDU: "In applicazione dell’art. 48 del D.P.R. 28.12.2000 n. 445 si informa che, ai sensi dell’art. 13 del Regolamento UE 2016/679 (GDPR), i dati forniti saranno utilizzati per lo svolgimento dell’istruttoria relativa al procedimento cui i dati si riferiscono. I dati saranno trattati dal personale in servizio presso l’U.O. Urbanisticae  Valorizzazione  del  Territorio, anche con l’ausilio di mezzi elettronici e potranno essere  comunicati  ai  soggetti  istituzionali  nei  soli  casi  previsti  dalle  disposizioni  di  legge  o  di  regolamento  o  a  terzi  interessati  nel  rispetto  della  normativa disciplinante il diritto di accesso. A tal proposito si ricorda che le dichiarazioni sostitutive rese nella presente istanza sono oggetto di controllo da parte dell’Amministrazione procedente ai sensi dell’art. 71 del D.P.R. 28.12.2000, n. 445. Soggetto designato per il trattamento dei dati è il Dirigente dell’U.O. Urbanisticae Valorizzazione del Territorio.I dati saranno trattati per il tempo stabilito dalla normativa nazionale: gliinteressati avranno il diritto di accedere ai propri dati personali e di ottenere la rettifica degli stessi nonché di rivolgersi all’autorità Garante per proporre reclamo.Una informativa completa ed aggiornata è pubblicata all’indirizzo:",
    ZoneNonMetanizzate: "Informativa D.Lgs. n. 196/2003: I dati sopra riportati sono prescritti dalle disposizioni vigenti ai fini del procedimento per la quale sono stati richiesti e saranno utilizzati esclusivamente a tale scopo",
    Specchi: "Ai sensi del D.Lgs. 30/06/2003, n. 196 e successive modificazioni e/o integrazioni, il sottoscritto prende, altresì, atto che: a) I dati contenuti nella presente domanda saranno trattati esclusivamente per le finalità connesse al rilascio del N.O. per l’installazione di specchio parabolico e saranno registrati e conservati , anche con l’ausilio di mezzi elettronici, ai soli fini del controllo e gestione degli stessi. b) La trasmissione di tali dati si rende obbligatoria, da parte dell’interessato, ai fini del rilascio del N.O.; un suo eventuale rifiuto ne produrrà il mancato rilascio. c) I dati così forniti potranno essere comunicati o trasmessi ad altri uffici esplicitamente coinvolti. d) L’interessato gode dei diritti conferitigli dagli artt. 7-8-9-10 del citato Decreto Legislativo 196/2003 e successive modificazioni e/o integrazioni. e) Titolare del trattamento di detti dati è il Comune di Perugia, nella persona del suo legale rappresentante. Nel caso in specie il responsabile è il Dirigente della Struttura Organizzativa Vigilanza, Dr.ssa Nicoletta Caponi.",
    Scavi: "In applicazione dell’art. 48 del D.P.R. 28.12.2000 n. 445 si informa che, ai  sensi  dell’art.  13  del  Regolamento  (UE)  2016/679  (GDPR), i  dati  da  Lei  forniti  saranno  utilizzati  per  lo svolgimento dell’istruttoria relativa all’istanza e saranno trattati, anche con l’ausilio di mezzi elettronici, esclusivamente dagli addetti agli uffici competenti in relazione ai dati/documenti/informazioni richiesti.I dati da Lei forniti potranno essere comunicati ai soggetti istituzionali nei soli casi previsti dalle disposizioni di leggeo di regolamento, o a terzi interessati nel rispetto della normativa disciplinante l’accesso. Soggetto designato per il trattamento dei dati è il dirigente competente in relazione ai dati/documenti /informazioni richiesti.I suoi dati saranno trattati per il tempo stabilito dalla normativa nazionale, e Lei ha il diritto di accedere ai suoi dati personali e di ottenere la rettifica degli stessi nonché di rivolgersi all’autorità Garante per proporre reclamo.Troverà un’informativa completa ed aggiornata all’indirizzo:"
  },
  cookies: {
    cookies: "Questo sito utilizza cookie tecnici e analytics.",
    navigazione: "Proseguendo nella navigazione accetti l’utilizzo dei cookie."
  }
}
