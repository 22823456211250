var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selected-users"},[(_vm.Value && _vm.Value.length)?[_vm._l((_vm.firstUsers),function(u,idx){return _c('div',{key:idx,staticClass:"user"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(((u.userName) + " (" + (_vm.userSummary(u.userId).email) + ")")),expression:"`${u.userName} (${userSummary(u.userId).email})`",modifiers:{"bottom":true}}],staticClass:"circle gravatar",style:(("border-color: " + (_vm.userColor(u.userName)) + ";background-color: " + (_vm.userColor(u.userName, 0.8)))),on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup()}}},[_vm._v(_vm._s(_vm.initials(u.userName) || '-'))])])}),(_vm.moreUsers)?_c('div',{staticClass:"user"},[_c('span',{staticClass:"circle gravatar",style:({
          'border-color': _vm.userColor('+'),
          'background-color': _vm.userColor('+', 0.8)
        }),on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup()}}},[_vm._v("+"+_vm._s(_vm.moreUsers))])]):_vm._e()]:_c('button',{staticClass:"circle dashed add",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();return _vm.togglePopup()}}},[_c('i',{staticClass:"fas fa-user-plus"})]),(_vm.isOpen)?_c('div',{staticClass:"user-list",style:({
      left: (_vm.horizontalPosition + "px"),
      top: (_vm.verticalPosition + "px")
    })},[_c('header',[_c('div',{staticClass:"input-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],attrs:{"type":"text","placeholder":_vm.$t('users.search', 'cerca')},domProps:{"value":(_vm.filter)},on:{"input":function($event){if($event.target.composing){ return; }_vm.filter=$event.target.value}}}),_c('button',{staticClass:"none",on:{"click":function($event){$event.stopPropagation();return _vm.editUsers($event)}}},[_c('i',{staticClass:"fas fa-search"})])])]),_c('article',_vm._l((_vm.filteredUsers),function(u){return _c('user',{key:u.id,attrs:{"user":{ id: u.id, userName: u.userName, email: u.email }},nativeOn:{"click":function($event){$event.stopPropagation();_vm.toggleUser(u);
          _vm.isOpen = false;}}},[_c('button',{staticClass:"btn btn-icon btn-re square",class:{ selected: _vm.isSelected(u) },on:{"click":function($event){$event.stopPropagation();_vm.toggleUser(u);
            _vm.isOpen = false;}}},[(!_vm.isSelected(u))?_c('i',{staticClass:"add fas fa-plus"}):_vm._e(),(_vm.isSelected(u))?_c('i',{staticClass:"selected fas fa-check"}):_vm._e(),(_vm.isSelected(u))?_c('i',{staticClass:"remove fas fa-times"}):_vm._e()])])}),1),_c('button',{staticClass:"close round warning",on:{"click":function($event){$event.stopPropagation();_vm.isOpen = false}}},[_c('i',{staticClass:"fas fa-times"})])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }