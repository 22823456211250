declare let __webpack_public_path__: string;
__webpack_public_path__ = process.env.BASE_URL;


import { usersService } from "@/modules/User/services/usersService";
import { store } from "@/store";
import { CommonRegistry, MessageService } from "vue-mf-module";
import { Projector } from "vue-mf-module/dist/helpers/Projector";
import { authService } from "./services/authService";

import RolesSelector from "@/modules/User/backOffice/components/rolesSelector/rolesSelector.vue";
import SelectedUsers from "@/modules/User/backOffice/components/selectedUsers/selectedUsers.vue";
import UserSelectorModal from "@/modules/User/backOffice/components/usersSelectorModal/usersSelectorModal.vue";


export default function registerComponents() {
  MessageService.Instance.reply("WHO_AM_I", async () => store.state.auth.userInfo || await authService.WhoAmI());
  MessageService.Instance.reply("AM_I_LOGGEDIN", async () => !!(await authService.WhoAmI()))

  MessageService.Instance.reply("FILL_USER_COLORS", (u: (user.item & { color: { background: string, border: string } })) => {
    u.color = Object.assign(u.color || {}, { border: usersService.getUserColor(u.userName), background: usersService.getUserColor(u.userName, .8) });
  })

  MessageService.Instance.reply("GET_USER_COLORS", (u) => ({ border: usersService.getUserColor(u), background: usersService.getUserColor(u, .8) }));
  MessageService.Instance.reply("SEARCH_FOR_USERS", async (users) => {
    let result = await Projector.Instance.projectAsyncTo(UserSelectorModal, users);
    return users || result;
  })

  MessageService.Instance.reply("USER_ROLES", async () => {

    return (await usersService.getMyRoles()).map(r => { var rr = r.split("|"); return rr[rr.length - 1] });
  });

  CommonRegistry.Instance.provideComponent(SelectedUsers, "users-selector");
  CommonRegistry.Instance.provideComponent(RolesSelector, "roles-selector");
}
