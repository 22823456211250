import Vue from "vue";
import { Prop, Component } from "vue-property-decorator";
import { Projector } from "vue-mf-module/dist/helpers/Projector";

import { usersService } from "@/modules/User/services/usersService";
import { MessageService } from "vue-mf-module";
import { debounce } from "@/services/debounce";
import { store } from "@/store";

import UserSelectorModal from "@/modules/User/backOffice/components/usersSelectorModal/usersSelectorModal.vue";
import user from "@/modules/User/backOffice/components/user/user.vue";

@Component({
  components: {
    user
  }
})
export default class SelectedUsers extends Vue {

  @Prop({ default: null })
  type!: string;

  @Prop({ default: null })
  id!: string;

  @Prop()
  value!: {
    id: string,
    userId: string,
    userName: string,
    email: string
  }[];

  @Prop({ default: false })
  readonly: boolean;

  isOpen: boolean = false;
  filter: string = "";

  get users(): user.summary[] { return store.state.auth.users; }

  get filteredUsers() {
    return this.users.filter(u => u.userName.indexOf(this.filter) >= 0 || u.email.indexOf(this.filter) >= 0)
  }

  get firstUsers() {
    return this.value.slice(0, 3);
  }

  get moreUsers() {
    if (this.value.length > 3) return this.value.length - 3;
    return 0;
  }

  get Value() {
    return this.value;
  }

  get horizontalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return window.innerWidth - bb.x - bb.width < 350 ? bb.x - 10 - 300 : bb.x + bb.width + 10;
  }

  get verticalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return window.innerHeight - bb.y - bb.height < 450 ? bb.y - 10 - 400 : bb.y;
  }

  async mounted() {
    SelectedUsers.loadData();
    MessageService.Instance.subscribe('CloseSelectedUsersPopup', () => this.isOpen = false)
  }

  async togglePopup() {
    if (this.readonly) return;
    var value = this.isOpen;
    MessageService.Instance.send("CloseSelectedUsersPopup");
    this.isOpen = !value;
  }

  userSummary(id: string): user.summary {
    return store.getters.auth.getUserSummary(id) || {} as any;
  }

  userColor(username: string, opacity: number = 100) {
    return usersService.getUserColor(username, opacity)
  }

  userhash(email: string) {
    return email;
  }

  initials(name: string) {
    return (name || '').split(' ').map(i => i[0]).join();
  }

  async editUsers() {
    await Projector.Instance.projectAsyncTo(UserSelectorModal, this.Value)
  }

  toggleUser(u: user.summary) {
    if (!this.isSelected(u))
      this.value?.push({ userName: u.userName, email: u.email, userId: u.id, id: u.id });
    else {

      var idx = this.value?.findIndex(i => i.userId == u.id);
      if (idx >= 0) this.value?.splice(idx, 1);
    }
    this.$emit('input', this.value);
  }

  isSelected(u: user.summary) {
    return this.value?.find(i => i.userId == u.id);
  }

  static loadData = debounce(async () => {
    store.actions.auth.setUsers((await usersService.getUsers()).map(u => ({ userName: u.userName, id: u.id, userId: u.id, email: u.email })))
  }, 1000)
}