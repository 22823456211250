import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { rolesServices } from '@/modules/User/services/rolesServices';
import { teamsServices } from '@/modules/User/services/teamsServices';
import { MessageService } from 'vue-mf-module';

@Component
export default class RolesSelector extends Vue {
  declare $t: any;

  @Prop()
  value!: Array<string>;

  @Prop()
  type!: string;

  isOpen: boolean = false;
  filter: string = '';
  roles: Array<string> = [];

  async mounted() {
    const localRoles: Array<role.item> = await rolesServices.getRoles();
    const localTeams: Array<role.team> = await teamsServices.getTeams();
    localRoles.map(r => this.roles.push(r.normalizedName));
    this.roles.push(...localTeams.map(i => `@${i.name}`));
    MessageService.Instance.subscribe('CloseSelectedRolesPopup', () => this.isOpen = false)
  }

  get filteredRoles() {
    return this.roles.filter(r => r.includes(this.filter.toUpperCase())).sort((a, b) => a.localeCompare(b));
  }

  get isActive() {
    return this.value?.length > 0;
  }

  async togglePopup() {
    var value = this.isOpen;
    MessageService.Instance.send("CloseSelectedRolesPopup");
    this.isOpen = !value;
  }

  toggleRole(r: string) {
    const found = this.value.some(sr => sr === r);
    if (!found) {
      this.value.push(r);
    } else {
      const idx = this.value.findIndex(sr => sr === r);
      if (idx >= 0) this.value.splice(idx, 1);
    }
    this.$emit('input', this.value);
  }

  isSelected(r: string) {
    return this.value.some(tr => tr === r);
  }

  horizontalPosition() {
    const bb = (this.$el as HTMLElement).getBoundingClientRect();
    if (bb.right + 200 > window.innerWidth)
      return `${window.innerWidth - bb.right - bb.width}px`;
    return '';
  }

  get verticalPosition() {
    var bb = this.$el.getBoundingClientRect();
    return window.innerHeight - bb.y - bb.height < 450 ? bb.y - 10 - 400 : bb.y;
  }
}
