import Vue from "vue";
import { Prop, Component} from "vue-property-decorator";

import { usersService } from "@/modules/User/services/usersService";

@Component
export default class User extends Vue {

  @Prop()
  user!: {
    userId: string,
    userName: string,
    email: string
  }

  userColor(username: string, opacity: number = 100) {
    return usersService.getUserColor(username, opacity) ?? 'F00'
  }

  initials(name: string) {
    return name.split(' ').map(i => i[0]).join('').toUpperCase();
  }

  get userhash() {
    return this.user?.email;
  }
}